import { showNotification } from '@mantine/notifications';
import { Check, X, Mail} from 'tabler-icons-react';
import React from "react";
import axios from "axios";

function ContactForm() {
  const [message, setMessage] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [name, setName] = React.useState("");
  const arn = "arn:aws:sns:us-west-1:935389031543:personal_website";

  function handleSubmit(e){
    e.preventDefault();
    let msg = "Name: " + name + "\n" + "Email: " + email + "\n" + "Message: " + message;

    const options = {
      method: 'POST',
      url: 'https://ht42g44crh.execute-api.us-west-1.amazonaws.com/Test/sendmessage',
      params: {
        message: msg,
        topic: arn
      },
      headers: {'content-type': 'application/json'}
    };
    
    axios.request(options)
    .catch(function (error) {
      console.error(error);
      showNotification({
        title: 'Message Not Sent :/',
        message: 'Please try again later.',
        icon: <X />,
        color: 'red'
      });
    });

    setMessage("");
    setEmail("");
    setName("");

    showNotification({
      title: 'Message Sent!',
      message: 'Thank you for reaching out.',
      icon: <Check />,
      color: 'green'
    });
  };

  return (
    <div id = "divForm">
      <form  onSubmit={handleSubmit}> 
       <h2>
          <b>Contact Me</b>
      </h2>
      <Mail
      size={'10%'}
      strokeWidth={2}
      color={'#3684C2'}/>
      <label htmlFor="name">
          <b>Name</b>
      </label>
      <input
          type="name"
          id="name"
          value ={name}
          name="name"
          onChange={(e) => setName(e.target.value)}
        />

      <label htmlFor="email">
      <b>Email</b>
      </label>
      <input
          type="name"
          id="email"
          value ={email}
          name="email"
          onChange={(e) => setEmail(e.target.value)}
        />
      
      <label id="label_message" htmlFor="message">
      <b>Message</b>
      </label>
      <textarea
        id = "message"
        value={message}
        name="message"
        onChange={(e) => setMessage(e.target.value)}
        cols="30" rows="5"
        />
        <input type="submit" value="Submit"/>
      </form>
    </div>
  )
}
  export default ContactForm;