import Intro from './intro.js'
import ContactForm from './contactForm.js'
import './App.css';
import { MantineProvider } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';

function App() {
  return (
    <MantineProvider withNormalizeCSS withGlobalStyles>
    <NotificationsProvider position="top-right">
    <div className="App">
      <div id="wrapper">
        <div class="content" id="left">
          <Intro/>
        </div>
        <div class="divider"></div>
        <div class="content" id="right">
          <ContactForm/>
        </div>
      </div>
    </div>
    </NotificationsProvider>
    </MantineProvider>
  );
}

export default App;
